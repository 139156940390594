@font-face {
    font-family: "Roboto-Light";
    src: url("./fonts/Roboto-Light.ttf");
}
@font-face {
    font-family: "Roboto-Thin";
    src: url("./fonts/Roboto-Thin.ttf");
}
@import "./componentes/navbar.scss";
@import "./componentes/loader.scss";
@import "./componentes/tabs.scss";
@import "./componentes/pop.scss";
@import "./componentes/slider.scss";
@import "./componentes/oxxo.scss";
body{
    background-color: #f2f0f5;
}
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ff8905; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
body{
    display: flex;
}
div#root {
    width: 100%;
}
.fa-1x{
    font-size: 20px;
}
.sp{
    padding: 0px !important;
}
.m-auto{
    margin: auto !important;
}
.ml-auto{
    margin-left: auto !important;
}
.centrar{
    display: flex;
    align-items: center;
}
.banner{
    background-image: url('../img/Compras-en-linea.jpg'), linear-gradient(#ebf6f7, #fff, #ebf6f7);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0px;
    filter: opacity(0.3);
    position: absolute;
    z-index: -1;
}
.card, .btn {
    border-radius: 5px !important;
    overflow: hidden;
}
.card.horizontal {
    max-height: 250px;
}
.card-ins {
    height: 215px;
}
.name {
    font-size: 12px;
}
.stiky{
    position: sticky;
    top:0px
}
.stiky-f{
    position: sticky;
    top:18%;
}
.nav-s {
    z-index: 200;
}
.sm{
    margin: 0px !important;
}
.close,.pointer{
    cursor: pointer;
}
.brs{
    border-right: solid #e0e0e0 1px;
}
.bls{
    border-left: solid #e0e0e0 1px;
}
select {
    display: block !important;
}
.oculto{
    display: none !important;
}
.circleSrvice{
    border-radius: 50%;
    border: solid;
    overflow: hidden;
    text-align: center;
    width: 150px;
    padding: 20px;
    height: 150px;
    box-shadow: 0px 10px 12px -2px;
    font-size: 32px;
}

.text-center{
    text-align: center;
}
.card-stacked>.card-content {
    padding: 10px !important;
    min-height: 150px;
}
.horizontal>.card-stacked>.card-content {
    padding: 20px;
    min-height: auto;
}
.negro{
    background-color: #000;
}
.card.horizontal .card-stacked {
    overflow-y: auto;
    overflow-x: hidden;
}
.banco {
    padding: 20px;
    color: #fff;
}
.clabe {
    border: solid;
    margin: 0px;
    width: max-content;
    margin: auto;
    padding: 0px 15px;
}
.alerta {
    position: fixed;
    right: -20%;
    top: 13%;
    background: #b72025;
    width: 20%;
    padding: 20px;
    border-radius: 5px;
    color: #000;
    box-shadow: -2px 10px 13px -8px #000;
    z-index: 10;
    animation: alerta 3s;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ff8905;
    background-color: #fff;
    border-color: #fff #fff #ff8905;
    border-bottom: solid;
    font-weight: 700;
    background: whitesmoke;
}
#myTabContent {
    padding: 20px;
    border: solid 2px #dfdfdf;
}
.bsl{
    border-left: solid 2px #dfdfdf;
    border-right: solid 2px #dfdfdf;
}
ul#myTab {
    padding-bottom: 10px;
    border: none;
}
@for $i from 0 through 100 {
    .fz-#{$i}{
        font-size: $i * 1px !important;
    }
    .w-#{$i}{
        width: $i * 1%;
    }
    .hvh-#{$i}{
        height: $i * 1vh;
    }
    .hminvh-#{$i}{
        min-height: $i * 1vh;
    }
    .h-#{$i}{
        height: $i * 1%;
    }
    .hpx-#{$i}{
        height: $i * 1px;
    }
    .ml-#{$i}{
        margin-left: $i * 1px;
    }
    .mr-#{$i}{
        margin-right: $i * 1px;
    }
    .mt-#{$i}{
        margin-top: $i * 1px !important;
    }
    .mb-#{$i}{
        margin-bottom: $i * 1px !important;
    }
    .p-#{$i}{
        padding: $i * 1px;
    }
    .pr-#{$i}{
        padding-right: $i * 1px !important;
    }
    .pl-#{$i}{
        padding-left: $i * 1px !important;
    }
    .pt-#{$i}{
        padding-top: $i * 1px;
    }
    .pb-#{$i}{
        padding-bottom: $i * 1px;
    }
}
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}
label.file {
    height: 40px;
    position: relative;
}
label.file2 {
    height: 40px;
    position: relative !important;
    div{
        height: 50px;
    }
}
.file:before {
    content: 'Seleccionar archivo';
    position: absolute;
    top: 22%;
    left: -75px;
    background: #212121;
    padding: 10px 15px;
    color: #fff;
    border-radius: 7px;
    cursor: pointer;
    width: max-content;
}
.file2:before {
    content: 'Seleccionar archivo';
    position: absolute;
    top: 127%;
    left: 3px;
    background: #212121;
    padding: 10px 15px;
    color: #fff;
    border-radius: 7px;
    cursor: pointer;
    width: max-content;
}
.active {
    background-color: transparent !important;
}
.dataFile {
    height: 44px;
    line-height: 44px;
    text-align: right;
    width: 100%;
    margin: auto;
    padding: 0px 13px;
    border: solid 2px #ededed;
    font-size: 15px;
    cursor: pointer;
}
.mn {
    margin: 0px !important;
}
.bg-orange{
    background-color: #b72025 !important;
}
.text-orange,h1,h2,h3,h4,h5,h6{
    color: #b72025 !important;
}
.cont-list{
    display: flex;
}
.tooltip{
    opacity: 0;
    position: absolute;
    left: 15.5%;
    padding: 3px 10px;
    color: #fff;
    transition: all .5s;
}
.cont-list:hover>.tooltip{
    opacity: 1;
}
.tarjeta{
    position: relative;
}
.imprimir {
    position: relative;
    top: 0px;
    left: calc(22% - 116px);
}
.w-max{
    width: max-content;
}
.cupon{
    position: relative;
    span {
        position: absolute;
        margin: 0px !important;
        left: 10px;
        top: -10px;
        padding: 5px;
    }
    input {
        border: solid 1px #000 !important;
        border-radius: 2px !important;
    }
}
.dark-text{
    color:#000 !important;
}
.righ-text{
    text-align: right;
}
.logoPago>img{
    width: 80px;
    margin-left: 20px;
}
[type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after {
    margin-top: 10px;
}
[type="radio"]:checked+span:before {
    margin-top: 10px;
}
[type="radio"]:checked+span:after {
    margin-top: 10px;
}
tr>td {
    border: 0px solid rgba(0,0,0,0.12) !important;
}
#curso-tab{
    position: relative;
    // top:10px;
}
.countAdd{
    transform: scale(1);
    animation: compra 1s;
}
.card-image>img {
    height: 250px;
    width: 100%;
}
.card-image.negro>img {
    height: 350px;
    width: 450px !important;
}
img.tumbail {
    height: 100% !important;
    object-fit: cover;
}
span.requi>p {
    margin: 0px;
}
.imgCart {
    height: 120px;
}
.bn{
    border: none;
}
span.correo {
    white-space: nowrap;
}
.tag{
    padding: 0px 7px;
    border-radius: 20px;
    font-size: 13px;
}
.card .card-image img {
    object-fit: cover;
}
.row2{
    display: flex;
}
.bg-orange.bages-cursos {
    position: absolute;
    left: 5%;
    top: 5%;
    z-index: 1;
    padding: 0px 10px;
    color: #fff;
    border-radius: 5px;
}
.logo-autor {
    position: absolute;
    z-index: 1;
    right: 5%;
    top: 5%;
}
.carrito>span{
    position: relative;
    top:-4px;
}
.text-grey{
    color: #6c6f7b !important;
}
.outline {
    background: transparent !important;
    border: solid 2px #b72025 !important;
    color: #b72025 !important;
    font-weight: 700;
}
.outline:hover {
    background: #b72025 !important;
    color: #ffffff !important;
}
.scontenido{
    display: contents;
}
.requerimientos0{
    color: #6c6f7b !important;
    font-weight: 700;
    font-size: 20px;   
}
.pointer{
    cursor: pointer;
}
@keyframes alerta {
    0%   {right: -20%;}
    50%  {right: 0%;}
    100% {right: -20%;}
}
@keyframes compra {
    0%   {transform: scale(1);background-color: red;}
    50%  {transform: scale(1.4);background-color: black;}
    100% {transform: scale(1);background-color: red;}
}
@media only screen and (max-width: 600px) {
    .card.horizontal {
        max-height: 250px;
    }
    .price{
        text-align: center !important;
        margin-top: 20px;
    }
    .footer-copyright>div>div{
        display: block;
    }
}