.cirNav{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0px 15px;
}
.logo{
    margin: 20px auto;
    width: 150px;
    padding-left: 7px;
}
.cart{
    position: relative;
    cursor: pointer;
}
.count {
    position: absolute;
    background-color: red;
    width: 15px;
    height: 15px;
    color: #fff;
    padding: 4px 0px;
    top: 11px;
    left: -10px;
    font-size: 10px;
    line-height: 6px;
    border-radius: 50%;
    padding-right: 2px;
}
.fondo {
    position: fixed;
    width: 100%;
    height: 191vh;
    left: 0px;
    padding: 0px;
    background: rgba(0,0,0,0.5);
    top:0px;
    opacity: 1;
    z-index: 2;
    animation: nav 1s;
}
.lateral {
    width: 14.7%;
    animation-name: fondo;
    animation-duration: 2s;
    padding: 21px 14px;
    overflow-y: auto;
    height: 100vh !important;
    overflow-x: hidden;
    font-family: Roboto-Light;
}
.marca {
    position: absolute;
    width: 17%;
    left: 5px;
    bottom: 91.2vh;
}
.tileNav{
    color: #6c6f7b;
    line-height: normal;
    font-weight: 600;
    font-size: 16px;
}
.power {
    white-space: pre;
}
.power>img {
    filter: brightness(0) invert(0.5);
}
.text-gray,.text-gray>li>a{
    color: #6c6f7b;
}
.text-gray>li>a:hover,.li-active{
    color: #f2951c;
}
.fondor{
    opacity:  0;
    animation: navRemove 1s;
}
.lateralr{
    width: 0px;
    animation: fondoRemove 1s;
}
.listmenu{
    opacity: 1;
    animation: nav 5s;
}
.listmenu>div>strong{
    font-size: 16px;
    color: #6c6f7b;
    line-height: normal;
    font-weight: 600;
}
.dropdown-content {
    display: block !important;
    opacity: 1 !important;
    right: 0 !important;
    min-width: 100px ;
    left: auto !important;
    top: 51px !important;
    z-index: 2 !important;
    width: 14.1% !important;
}
.breadcrumb-div {
    background-color: #BFBFBF;
    padding: 4.5px 19px;
    box-shadow: 0 4px 2px -3px grey;
    font-size: 14px;
    width: 100%;

}
.breadcrumb-div2 {
    padding: 4.5px 19px;
    font-size: 14px;
    width: 80%;
    margin: 10px auto;
    // position: absolute;
    // left: 10%;
}
.cuadro{
    height: 64px;
    position: relative;
}
.cuadro:hover{
    background-color: rgba(0, 0, 0, 0.1);
}
.tooltip-left{
    position: absolute;
    opacity: 0;
    padding: 10px 8px;
    font-size: 1rem;
    border-radius: 2px;
    min-height: 36px;
    line-height: 120%;
    position: absolute;
    text-align: center;
    // max-width: calc(100% - 4px);
    width: max-content;
    overflow: hidden;
    top: 12px;
    left: 0;
    pointer-events: none;
    transform: translateX(-105%) translateY(0px);
    transition: opacity .5s, transform .5s;
}
.cuadro:hover>div>.tooltip-left {
    opacity: 1;
    transform: translateX(-115%) translateY(0px);
}
.dropdown-content li>a, .dropdown-content li>span {
    padding: 14px 15px !important;
    font-family: Roboto-Thin;
}
.dropdown-content li {
    min-height: 35px !important;
    margin:0px;
}
.dropdown-content li.divider {
    min-height: 0 !important;
    height: 1px !important;
}
.space {
    width: 85%;
    height: 100vh;
    position: absolute;
    right: 0px;
    top: 0px;
}
.dropActive {
    width: 100%;
    height: 100vh;
    background: transparent;
    position: fixed;
    z-index: 1;
}
.newBuy {
    position: fixed;
    width: 100%;
    // top: 29%;
    right: 0px;
    z-index: 1;
}
.imgBuy {
    height: 177px;
    object-fit: cover;
}
@keyframes fondo {
    0% {width: 0px;}
    100% {width: 14.7%;}
}
@keyframes nav {
    0% {opacity: 0;display: none;}
    100% {opacity: 1;display: block;}
}
@keyframes fondoRemove {
    0% {width: 14.7%;}
    100% {width: 0px;}
}
@keyframes navRemove {
    0% {opacity: 1;}
    100% {opacity: 0;}
}
@media only screen and (max-width: 600px) {
    .lateral {
      width: 50%;
      animation: fondo1 2s;
      padding: 15px 5px;
    }
    .dropdown-content{
        width: 50% !important;
        top: 56px !important;
        overflow: auto;
        height: 87vh;
    }
    @keyframes fondo1 {
      0% {width: 0px;}
      100% {width: 50%;}
    }
}