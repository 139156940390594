.bd-example-tabs .nav-tabs {
    margin-bottom: 1rem;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}
.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.nav-tabs .nav-item {
    margin-bottom: -1px;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.nav-link {
    display: block;
    padding: .5rem 1rem;
    cursor: pointer;
}

.tab-content>.tab-pane {
    display: none;
}
.fade {
    transition: opacity .15s linear;
}
.tab-content>.tab-pane {
    display: none;
}
.fade {
    transition: opacity .15s linear;
}
.tab-content>.active {
    display: block;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.fade:not(.show) {
    opacity: 0;
}